import React from 'react';
import { Router as BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { Provider as AuthProvider } from 'contexts/AuthContext';
import { history } from 'utils/history';
import { Router } from 'components/Router';
import { routes } from 'routes';
import Amplify from 'aws-amplify';

// https://www.materialpalette.com/colors
const theme = createTheme({
  // palette: {
  //   primary: {
  //     main: indigo[800],
  //   },
  //   secondary: {
  //     main: indigo[400],
  //   },
  // },
});

Amplify.configure({
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_QniD92ga3',
  aws_user_pools_web_client_id: '4agjdg9oli4f95rkvqqg2t2tiq',
});

export const App = () => (
  <>
    <CssBaseline />
    <BrowserRouter history={history}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Router routes={routes} />
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  </>
);
