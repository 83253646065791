export const Config = {
  SHOW_LOGS: true,
  DISPLAY_MODE: {
    MONTHLY: 'monthly',
    DAILY: 'daily',
    CHART: 'chart',
  },
  REPORT_TYPE: {
    SCANNERS: 'scanners',
    STORES: 'stores',
    SCANNERS_AND_STORES: 'scanners-and-stores',
  },
  CUSTOMERS: {
    SEPHORA_NA: 'Sephora NA',
    SEPHORA_SEA: 'Sephora SEA',
    SEPHORA_EMEA: 'Sephora EMEA',
    WBA: 'WBA',
    JPMS: 'JPMS',
    SUPERPHARM: 'Superpharm',
    BLUSHBAR: 'Blushbar',
    ULTA: 'Ulta',
  },
  AUTH: {
    ADMIN: {
      ID: -1,
      NAME: 'admin@fitskin.co',
    },
    SEPHORA_NA: {
      ID: 1,
      NAME: 'Sephora NA',
    },
    SEPHORA_SEA: {
      ID: 2,
      NAME: 'Sephora SEA',
    },
    SEPHORA_EMEA: {
      ID: 3,
      NAME: 'Sephora EMEA',
    },
    WBA: {
      ID: 4,
      NAME: 'WBA',
    },
    JPMS: {
      ID: 5,
      NAME: 'JPMS',
    },
    SUPERPHARM: {
      ID: 6,
      NAME: 'Superpharm',
    },
    QAT: {
      ID: 7,
      NAME: 'Synergy',
    },
    BLUSHBAR: {
      ID: 8,
      NAME: 'Blushbar',
    },
    ULTA: {
      ID: 9,
      NAME: 'Ulta',
    },
  },
  ROLES: {
    ADMIN: 'Admin',
    CUSTOMER: 'Customer',
    QAT: 'QAT',
    UNAUTHINCATED: 'Unauthenticated',
  },
};
