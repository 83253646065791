import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CircularLoader } from 'components/Controls';
import { getQatValidationItem } from 'api';
import queryString from 'query-string';
import { Form } from 'components/Controls';
import { CSVLink } from 'react-csv';

export const QatValidationDetailsScreen = ({ location }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      //marginLeft: theme.spacing(2),
      border: '1px solid rgba(0, 0, 0, 0.12)',
      padding: '10px',
    },
    pageTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 24,
      fontWeight: 'bold',
    },
    selectInput: {
      //zIndex: 100,
      marginTop: 16,
      marginBottom: 8,
      marginLeft: 10,
      marginRight: 10,
    },
    paper: {
      width: '110px',
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    legendCell: {
      width: '200px',
      height: '80px',
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    legendCellFailedBoth: {
      ...theme.legendCell,
      backgroundColor: '#ef5350',
    },
    legendCellElectrinical: {
      ...theme.legendCell,
      backgroundColor: '#ffcdd2',
    },
    legendCellAssembly: {
      ...theme.legendCell,
      backgroundColor: '#ef9a9a',
    },
    legendNonElectronical: {
      ...theme.legendCell,
      backgroundColor: '#e2f1f8',
    },
    legendNonAssembly: {
      ...theme.legendCell,
      backgroundColor: '#c1d5e0',
    },
    legendNonBlank: {
      ...theme.legendCell,
      backgroundColor: '#FFFFFF',
    },
    failedBoth: {
      width: '110px',
      padding: theme.spacing(1),
      fontSize: 14,
      backgroundColor: '#ef5350',
      fontWeight: 'bold',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    failedElectrinical: {
      width: '110px',
      padding: theme.spacing(1),
      fontSize: 14,
      backgroundColor: '#ffcdd2',
      fontWeight: 'normal',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    nonElectrinicalChecked: {
      width: '110px',
      padding: theme.spacing(1),
      fontSize: 14,
      backgroundColor: '#e2f1f8',
      fontWeight: 'normal',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    failedAssembly: {
      width: '110px',
      padding: theme.spacing(1),
      fontSize: 14,
      backgroundColor: '#ef9a9a',
      fontWeight: 'normal',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    nonAssemblyChecked: {
      width: '110px',
      padding: theme.spacing(1),
      fontSize: 14,
      backgroundColor: '#c1d5e0',
      fontWeight: 'normal',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    csvLink: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
  }));
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [csvResultData, setCsvResultData] = useState(null);
  const [data, setData] = useState();
  const [totalFailed, setTotalFailed] = useState();
  const [scannersChecked, setScannersChecked] = useState(0);
  const [totalOfAtLeastOneFailed, setTotalOfAtLeastOneFailed] = useState(0);

  useEffect(() => {
    (async function fetchQatValidationItem() {
      try {
        const qs = queryString.parse(location.search);

        const { id } = qs;
        setIsLoading(true);
        const validationResult = await getQatValidationItem(id);

        if (validationResult) {
          const { response, scanners } = validationResult || {
            response: {},
            scanners: [],
          };
          const { electronicalFails, assemblyFails } = response || {
            electronicalFails: [],
            assemblyFails: [],
          };

          const totalFailed = [];
          const atLeastOneFailed = [];

          for (let scanner of scanners) {
            const electronicalFailed = electronicalFails?.find((fail) => {
              return fail === scanner;
            });

            const assemblyFailed = assemblyFails?.find((fail) => {
              return fail === scanner;
            });

            if (electronicalFailed && assemblyFailed) {
              totalFailed.push(scanner);
            }

            if (electronicalFailed || assemblyFailed) {
              atLeastOneFailed.push(scanner);
            }
          }

          setScannersChecked(scanners.length);
          setTotalOfAtLeastOneFailed(atLeastOneFailed.length);
          setCsvResultData(atLeastOneFailed.join('\n'));

          if (totalFailed.length > 0) {
            setTotalFailed(totalFailed.length);
          }
        }

        setData(validationResult);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  console.log(data);

  const renderScannersGrid = (validationResultData) => {
    const { scanners, response } = validationResultData || {
      scanners: [],
      response: {},
    };
    const {
      electronicalFails,
      assemblyFails,
      nonValidatedScannersInElectronicalTests,
      nonValidatedScannersInAssemblyTests,
    } = response || {
      electronicalFails: [],
      assemblyFails: [],
      nonValidatedScannersInElectronicalTests: [],
      nonValidatedScannersInAssemblyTests: [],
    };

    const getCellStyle = (scanner) => {
      const electronicalFailed = electronicalFails?.find((fail) => {
        return fail === scanner;
      });

      const assemblyFailed = assemblyFails?.find((fail) => {
        return fail === scanner;
      });

      const nonCheckedScannerForElectronicalTests =
        nonValidatedScannersInElectronicalTests?.find((nonCheckedScanner) => {
          return nonCheckedScanner === scanner;
        });

      const nonCheckedScannerForAssemblyTests =
        nonValidatedScannersInAssemblyTests?.find((nonCheckedScanner) => {
          return nonCheckedScanner === scanner;
        });

      const isPropNullOrEmpty = (prop) => {
        return prop === null || prop === undefined || prop === '';
      };

      if (!isPropNullOrEmpty(nonCheckedScannerForElectronicalTests)) {
        return classes.nonElectrinicalChecked;
      }
      if (!isPropNullOrEmpty(nonCheckedScannerForAssemblyTests)) {
        return classes.nonAssemblyChecked;
      }

      if (
        !isPropNullOrEmpty(electronicalFailed) &&
        !isPropNullOrEmpty(assemblyFailed)
      ) {
        return classes.failedBoth;
      }
      if (!isPropNullOrEmpty(electronicalFailed)) {
        return classes.failedElectrinical;
      }
      if (!isPropNullOrEmpty(assemblyFailed)) {
        return classes.failedAssembly;
      }

      return classes.paper;
    };

    if (scanners && scanners.length > 0) {
      return scanners.map((cell, index) => {
        return (
          <Grid item>
            <Paper className={getCellStyle(cell)}>{cell}</Paper>
          </Grid>
        );
      });
    }
    return null;
  };

  return (
    <div>
      <div>
        <Typography className={classes.pageTitle} align="left">
          QAT - Validation Result
        </Typography>
      </div>

      <div style={{ marginTop: 30 }}>
        {!isLoading && data && (
          <>
            <Grid container spacing={3}>
              <Grid item className={classes.legendCellFailedBoth}>
                <span>
                  ({totalFailed || 0}) Failed Both: electronical test and
                  assembly
                </span>
              </Grid>
              <Grid item className={classes.legendCellElectrinical}>
                <span>
                  ({data?.response?.electronicalFails?.length || 0}) Failed
                  electronical
                </span>
              </Grid>
              <Grid item className={classes.legendCellAssembly}>
                <span>
                  ({data?.response?.assemblyFails?.length || 0}) Failed assembly
                  test
                </span>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: 20 }}>
              <Grid item className={classes.legendNonElectronical}>
                <span>
                  (
                  {data?.response?.nonValidatedScannersInElectronicalTests
                    ?.length || 0}
                  ) Non validated scanners in electronical tests
                </span>
              </Grid>
              <Grid item className={classes.legendNonAssembly}>
                <span>
                  (
                  {data?.response?.nonValidatedScannersInAssemblyTests
                    ?.length || 0}
                  ) Non validated scanners in assembly tests
                </span>
              </Grid>
            </Grid>
            <Grid className={classes.csvLink} item>
              {csvResultData && !isLoading && (
                <>
                  <CSVLink filename={`${data?.file}`} data={csvResultData}>
                    <Typography className={classes.labelAction} align="left">
                      Export failed scanners
                    </Typography>
                  </CSVLink>
                  <div>
                    <span>
                      Scanners checked ({scannersChecked}). Can be duplicates in
                      csv file
                    </span>
                  </div>
                  <div>
                    <span>
                      Scanners that failed at least once (
                      {totalOfAtLeastOneFailed}). Either assembly or
                      electronical
                    </span>
                  </div>
                </>
              )}
            </Grid>
            <Grid container spacing={0}>
              <Grid container spacing={0}>
                {renderScannersGrid(data)}
              </Grid>
            </Grid>
          </>
        )}
      </div>
      {isLoading && <CircularLoader />}
    </div>
  );
};
