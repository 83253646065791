import React, { useContext, useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CircularLoader } from 'components/Controls';
import BarChartIcon from '@material-ui/icons/BarChart';
import {
  getBatchTransformJobResults,
  getBatchTransformUvJobResults,
} from 'api';
import { Form } from 'components/Controls';
import Select from 'react-select';
import { getCustomersThatRunsML } from './controls/customers';
import { Context as AuthContext } from 'contexts/AuthContext';
import { Config } from 'configs';

export const MLScreen = ({ location }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      //marginLeft: theme.spacing(2),
      border: '1px solid rgba(0, 0, 0, 0.12)',
      padding: '10px',
    },
    header: {
      backgroundColor: 'lightgray',
      color: theme.palette.text.primary,
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paperLeft: {
      padding: theme.spacing(1),
      textAlign: 'left',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
    },
    paperRight: {
      padding: theme.spacing(1),
      textAlign: 'right',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
    },
    pageTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 24,
      fontWeight: 'bold',
    },
    categoryTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 18,
      fontWeight: 'bold',
    },
    cell: {
      width: '100%',
    },
    thresholdCell: {
      ...theme.legendCell,
      backgroundColor: '#e2f1f8',
    },
    thresholdCellAlt: {
      ...theme.legendCell,
      backgroundColor: '#c1d5e0',
    },
    selectInput: {
      //zIndex: 100,
      marginTop: 16,
      marginBottom: 8,
      marginLeft: 10,
      marginRight: 10,
    },
    paperBold: {
      width: '110px',
      padding: theme.spacing(1),
      fontSize: 14,
      backgroundColor: '#e0ffee',
      fontWeight: 'bold',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    csvLink: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  const transformSource = (sourceItems) => {
    const sourceItemsFiltered = sourceItems?.filter(
      (customer) => customer.ID !== -1,
    );

    return sourceItemsFiltered?.map((item) => {
      return {
        value: item.ID,
        label: item.NAME,
      };
    });
  };

  const dashboardLinks = [
    {
      client: 4,
      type: {
        anomalies:
          'https://charts.mongodb.com/charts-warehouse-uhqyz/dashboards/5bf75796-480a-40be-b60c-f4ba74fa9ca5',
        uv: 'https://charts.mongodb.com/charts-warehouse-uhqyz/dashboards/9144dfaa-582f-4729-a0d4-c208ce434b53',
      },
    },
    {
      client: 1,
      type: {
        anomalies:
          'https://charts.mongodb.com/charts-warehouse-uhqyz/dashboards/6145f433-d9a8-4251-ab39-ef0847258d50',
        uv: 'https://charts.mongodb.com/charts-warehouse-uhqyz/dashboards/b90c3a10-a462-4ed1-bfaa-71d13cd0cba8',
      },
    },
  ];

  const getDashboardLink = (type) => {
    const dashboard = dashboardLinks.find(
      (item) => item.client === selectedCustomer?.value,
    );

    if (dashboard) {
      return dashboard.type[type];
    }

    return '';
  };

  const { state: authState } = useContext(AuthContext);
  const [customers] = useState(transformSource(getCustomersThatRunsML()) || []);
  const [anomaliesJobs, setAnomaliesJobs] = useState([]);
  const [uvJobs, setUvJobs] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(
    authState.user?.role === Config.ROLES.CUSTOMER
      ? {
          value: authState.user.id,
          label: authState.user.customer,
        }
      : null,
  );

  const [isLoading, setIsLoading] = useState(false);

  const hasData =
    (uvJobs != null && uvJobs.length > 0) ||
    (anomaliesJobs != null && anomaliesJobs.length > 0);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      if (selectedCustomer) {
        const anomalies = await getBatchTransformJobResults(
          selectedCustomer.value,
        );
        setAnomaliesJobs(anomalies || []);
        const uv = await getBatchTransformUvJobResults(selectedCustomer.value);
        setUvJobs(uv || []);
      }

      setIsLoading(false);
    };
    fetchData();
  }, [selectedCustomer]);

  const compareValues = (jobValue, threshold) => {
    if (!threshold || threshold.value === null) return false;
    switch (threshold.compareBy) {
      case 'lt':
        return jobValue < threshold.value;
      case 'gt':
        return jobValue > threshold.value;
      default:
        return false;
    }
  };

  const renderDataAnomalies = (finishedJobs) => {
    const thresholds = getAnomaliesThresholds();

    let rows = [];
    if (finishedJobs && finishedJobs.length > 0) {
      finishedJobs.forEach((job) => {
        let outputCells = [];
        outputCells.push({
          value: job.date.substring(0, 10),
          isAnomalous: false,
        });
        outputCells.push({ value: job.model, isAnomalous: false });
        outputCells.push({
          value: job.valid,
          isAnomalous: compareValues(job.valid, thresholds.valid),
        });
        outputCells.push({
          value: job.makeup,
          isAnomalous: compareValues(job.makeup, thresholds.makeup),
        });
        outputCells.push({
          value: job.out_of_focus,
          isAnomalous: compareValues(job.out_of_focus, thresholds.out_of_focus),
        });
        outputCells.push({
          value: job.incorrect_placement,
          isAnomalous: compareValues(
            job.incorrect_placement,
            thresholds.incorrect_placement,
          ),
        });
        outputCells.push({
          value: job.complexion_inflammation,
          isAnomalous: compareValues(
            job.complexion_inflammation,
            thresholds.complexion_inflammation,
          ),
        });
        outputCells.push({
          value: job.not_skin,
          isAnomalous: compareValues(job.not_skin, thresholds.not_skin),
        });
        outputCells.push({
          value: job.hair,
          isAnomalous: compareValues(job.hair, thresholds.hair),
        });
        outputCells.push({
          value: job.foreign_object,
          isAnomalous: compareValues(
            job.foreign_object,
            thresholds.foreign_object,
          ),
        });
        rows.push(outputCells);
      });
    }

    return rows.map((row, index) => (
      <div
        key={index}
        style={{
          display: 'grid',
          gridTemplateColumns:
            '180px 80px 120px 120px 120px 150px 180px 120px 120px 120px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        {row.map((cell, cellIndex) => (
          <Grid key={cellIndex} item className={classes.cell}>
            <Paper
              className={cellIndex >= 2 ? classes.paperRight : classes.paper}
              style={{
                backgroundColor: cell.isAnomalous ? '#ffcdd2' : undefined,
              }}
            >
              {cellIndex >= 2
                ? `${(parseFloat(cell.value) * 100).toFixed(2)}%`
                : cell.value}
            </Paper>
          </Grid>
        ))}
      </div>
    ));
  };

  const renderDataAUv = (finishedJobs) => {
    const thresholds = getUvAnomaliesThresholds();

    let rows = [];
    if (finishedJobs && finishedJobs.length > 0) {
      finishedJobs.forEach((job) => {
        let outputCells = [];
        outputCells.push({
          value: job.date.substring(0, 10),
          isAnomalous: false,
        });
        outputCells.push({ value: job.model, isAnomalous: false });
        outputCells.push({
          value: job.valid,
          isAnomalous: compareValues(job.valid, thresholds.valid),
        });
        outputCells.push({
          value: job.black,
          isAnomalous: compareValues(job.black, thresholds.black),
        });
        outputCells.push({
          value: job.lift_black,
          isAnomalous: compareValues(job.lift_black, thresholds.lift_black),
        });
        outputCells.push({
          value: job.lift_off,
          isAnomalous: compareValues(job.lift_off, thresholds.lift_off),
        });
        outputCells.push({
          value: job.missing,
          isAnomalous: compareValues(job.missing, thresholds.missing),
        });
        rows.push(outputCells);
      });
    }

    return rows.map((row, index) => (
      <div
        key={index}
        style={{
          display: 'grid',
          gridTemplateColumns: '180px 80px 120px 120px 120px 150px 180px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        {row.map((cell, cellIndex) => (
          <Grid key={cellIndex} item className={classes.cell}>
            <Paper
              className={cellIndex >= 2 ? classes.paperRight : classes.paper}
              style={{
                backgroundColor: cell.isAnomalous ? '#ffcdd2' : undefined,
              }}
            >
              {cellIndex >= 2
                ? `${(parseFloat(cell.value) * 100).toFixed(2)}%`
                : cell.value}
            </Paper>
          </Grid>
        ))}
      </div>
    ));
  };

  const getAnomaliesThresholds = (customer) => {
    return {
      valid: { value: 0.75, compareBy: 'lt' },
      out_of_focus: { value: 0.05, compareBy: 'gt' },
      incorrect_placement: { value: 0.05, compareBy: 'gt' },
      foreign_object: { value: 0.01, compareBy: 'gt' },
    };
  };

  const renderThresholdsHeaderAnomalies = () => {
    const thresholds = getAnomaliesThresholds();

    const valid = (thresholds?.valid?.value || 0) * 100;
    // const makeup = (thresholds?.makeup?.value || 0) * 100;
    const out_of_focus = (thresholds?.out_of_focus?.value || 0) * 100;
    const incorrect_placement =
      (thresholds?.incorrect_placement?.value || 0) * 100;
    // const complexion_inflammation =
    //   (thresholds?.complexion_inflammation?.value || 0) * 100;
    // const not_skin = (thresholds?.not_skin?.value || 0) * 100;
    // const hair = (thresholds?.hair?.value || 0) * 100;
    const foreign_object = (thresholds?.foreign_object?.value || 0) * 100;

    return (
      <div style={{ marginTop: 30, marginBottom: 30 }}>
        <div style={{ paddingBottom: 10 }}>
          <Typography className={classes.categoryTitle} align="left">
            Anomalies thresholds
          </Typography>
        </div>
        <Grid container spacing={3} style={{ marginLeft: 0 }}>
          <Grid item className={classes.thresholdCell}>
            <span>
              Valid {'<'} {valid.toFixed(2)} %
            </span>
          </Grid>
          {/* <Grid item className={classes.thresholdCellAlt}>
            <span>({makeup} %) Makeup</span>
          </Grid> */}
          <Grid item className={classes.thresholdCellAlt}>
            <span>
              Out of focus {'>'} {out_of_focus.toFixed(2)} %
            </span>
          </Grid>
          <Grid item className={classes.thresholdCell}>
            <span>
              Incorrect placement {'>'} {incorrect_placement.toFixed(2)} %
            </span>
          </Grid>
          {/* <Grid item className={classes.thresholdCell}>
            <span>({complexion_inflammation.toFixed(2)} %) Complexion inflammation</span>
          </Grid>
          <Grid item className={classes.thresholdCellAlt}>
            <span>({not_skin.toFixed(2)} %) Not skin</span>
          </Grid>
          <Grid item className={classes.thresholdCell}>
            <span>({hair.toFixed(2)} %) Hair</span>
          </Grid> */}
          <Grid item className={classes.thresholdCellAlt}>
            <span>
              Foreign object {'>'} {foreign_object.toFixed(2)} %
            </span>
          </Grid>
        </Grid>
      </div>
    );
  };

  const getUvAnomaliesThresholds = (customer) => {
    return {
      valid: { value: 0.26, compareBy: 'lt' },
      black: { value: 0.15, compareBy: 'gt' },
      lift_black: { value: 0.15, compareBy: 'gt' },
      // missing: { value: 0.15, compareBy: 'gt' },
    };
  };

  const renderThresholdsHeaderUv = () => {
    const thresholds = getUvAnomaliesThresholds();

    const valid = (thresholds?.valid?.value || 0) * 100;
    const black = (thresholds?.black?.value || 0) * 100;
    const lift_black = (thresholds?.lift_black?.value || 0) * 100;
    // const lift_off = (thresholds?.lift_off?.value || 0) * 100;
    // const missing = (thresholds?.missing?.value || 0) * 100;

    return (
      <div style={{ marginTop: 30, marginBottom: 30 }}>
        <div style={{ paddingBottom: 10 }}>
          <Typography className={classes.categoryTitle} align="left">
            UV thresholds
          </Typography>
        </div>
        <Grid container spacing={3} style={{ marginLeft: 0 }}>
          <Grid item className={classes.thresholdCell}>
            <span>
              Valid {'<'} {valid.toFixed(2)} %
            </span>
          </Grid>
          <Grid item className={classes.thresholdCellAlt}>
            <span>
              Black {'>'} {black.toFixed(2)} %
            </span>
          </Grid>
          <Grid item className={classes.thresholdCell}>
            <span>
              Lift black {'>'} {lift_black.toFixed(2)} %
            </span>
          </Grid>
          {/* <Grid item className={classes.thresholdCellAlt}>
            <span>
              Lift off {'>'} {lift_off.toFixed(2)} %
            </span>
          </Grid> */}
          {/* <Grid item className={classes.thresholdCell}>
            <span>
              Missing {'>'} {missing.toFixed(2)} %
            </span>
          </Grid> */}
        </Grid>
      </div>
    );
  };

  const renderHeaderAnomalies = () => {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns:
            '180px 80px 120px 120px 120px 150px 180px 120px 120px 120px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <div>
          <Paper className={classes.header}>
            <span>Date</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Model</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Valid</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Makeup</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Out of focus</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Incorrect placement</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Complexion inflammation</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Not skin</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Hair</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Foreign object</span>
          </Paper>
        </div>
      </div>
    );
  };
  const renderHeaderUv = (dates) => {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '180px 80px 120px 120px 120px 150px 180px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <div>
          <Paper className={classes.header}>
            <span>Date</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Model</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Valid</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Black</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Lift black</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Lift off</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Missing</span>
          </Paper>
        </div>
      </div>
    );
  };

  const renderGrid = (type) => {
    if (!hasData) {
      return null;
    }

    return (
      <div>
        <Grid item spacing={0}>
          {type === 'anomalies' && (
            <div>{renderThresholdsHeaderAnomalies()}</div>
          )}
          {type === 'uv' && <div>{renderThresholdsHeaderUv()}</div>}

          <div>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <Typography className={classes.categoryTitle} align="left">
                Last 10 Batch Transform Inference
              </Typography>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={getDashboardLink(type)}
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
              >
                <BarChartIcon style={{ fontSize: '2rem' }} />
              </a>
            </div>
            <Grid item spacing={0}>
              {type === 'anomalies' && anomaliesJobs && (
                <>
                  {renderHeaderAnomalies()}
                  {renderDataAnomalies(anomaliesJobs)}
                </>
              )}
              {type === 'uv' && uvJobs && (
                <>
                  {renderHeaderUv()}
                  {renderDataAUv(uvJobs)}
                </>
              )}
            </Grid>
          </div>
        </Grid>
      </div>
    );
  };

  return (
    <div>
      <div>
        <Typography className={classes.pageTitle} align="left">
          Batch Transform Inference.
        </Typography>
      </div>
      <div className={classes.root}>
        <Form>
          <Grid container direction="column" item xs={12}>
            {authState.user?.role === Config.ROLES.ADMIN && (
              <Grid item alignItems="center" xs={3}>
                <Select
                  onChange={(selectedItem) => {
                    setSelectedCustomer(selectedItem);
                  }}
                  placeholder="Select client"
                  className={classes.selectInput}
                  options={customers}
                />
              </Grid>
            )}
          </Grid>
        </Form>
      </div>
      <div style={{ marginTop: 30 }}></div>

      {!isLoading && renderGrid('anomalies')}
      <div style={{ marginTop: 30 }}></div>

      {!isLoading && renderGrid('uv')}
      {isLoading && <CircularLoader />}
    </div>
  );
};
