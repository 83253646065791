import React from 'react';
import { useSnackbar } from 'notistack';
import { Button } from '@material-ui/core';
import { Trans } from 'react-i18next';

const InnerSnackbarUtilsConfigurator = ({ setUseSnackbarRef }) => {
  setUseSnackbarRef(useSnackbar());

  return null;
};
let useSnackbarRef;
const setUseSnackbarRef = (useSnackbarRefProp) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => (
  <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
);

export const SnackbarUtils = {
  success(msg, persist) {
    this.toast(msg, 'success', persist);
  },
  warning(msg, persist) {
    this.toast(msg, 'warning', persist);
  },
  info(msg, persist) {
    this.toast(msg, 'info', persist);
  },
  error(msg, persist) {
    this.toast(msg, 'error', persist);
  },
  toast(msg, variant = 'default', persist = false) {
    // eslint-disable-next-line no-unused-expressions
    useSnackbarRef
      ? useSnackbarRef.enqueueSnackbar(msg, {
        variant,
        persist,
        ...(persist
          ? {
            action: (key) => (
              <Button
                style={{ color: 'white' }}
                onClick={() => useSnackbarRef.closeSnackbar(key)}
              >
                <Trans>snackbar_dismiss</Trans>
              </Button>
            ),
          }
          : {}),
        style: { whiteSpace: 'pre-line' },
      })
      : '';
  },
};
