import axios from 'axios';
import { Config } from 'configs';
import { Auth } from 'aws-amplify';

const getEndpoint = () => {
  const localApi = process.env.REACT_APP_API_URL_LOCAL;
  const remoteApi = process.env.REACT_APP_API_URL;
  const devMode = process.env.REACT_APP_DEV_MODE
    ? process.env.REACT_APP_DEV_MODE
    : false;
  Config.SHOW_LOGS && console.log('DEV MODE USED', devMode);
  return devMode === 'true' ? localApi : remoteApi;
};

console.log('ENDPOINT USED', getEndpoint());

const ApiRoutes = {
  getScansFromSnapshots: '/get-scans',
  getScans: '/v2/get-scans',
  getSourceTypes: '/v2/get-source-types?customer=',
  getStoreScanPairs: '/get-store-scanner-pairs',
  postPair: '/pair-store-and-scanner',
  getWbaXpolScores: '/wba-xpol-scores',
  getAppVersions: '/v2/get-app-versions',
  getUploads: '/v2/get-images-count-structure',
  getScanners: '/v2/get-scanners',
  getScannersByAppVersion: '/v2/get-scanners-by-app-version',
  getStores: '/get-stores',
  getBatchTransformJobResults: '/v2/get-batch-transform-job-results',
  getBatchTransformUvJobResults: '/v2/get-batch-transform-job-uv-results',
  getStoresUsage: '/get-stores-usage',
  qatResult: '/qat',
  qatResults: '/qat',
  qatResultItem: '/qat-item',
};

const api = axios.create({
  baseURL: getEndpoint(),
});

api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    Config.SHOW_LOGS && console.log(error);
    Promise.reject(error);
  },
);

api.interceptors.request.use(
  async (config) => {
    const idToken = await getAccessJwtToken();
    config.headers['Authorization'] = idToken;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const getAccessJwtToken = async () => {
  // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
  const session = await Auth.currentSession();
  const tokenFromSession = session.getIdToken().getJwtToken();
  return tokenFromSession;
};

export const getScans = async (
  customer,
  mode,
  year,
  month,
  source,
  reportType,
) => {
  let dMode =
    mode &&
    (mode === Config.DISPLAY_MODE.MONTHLY || mode === Config.DISPLAY_MODE.DAILY)
      ? mode
      : Config.DISPLAY_MODE.MONTHLY;

  let apiUrl = `${ApiRoutes.getScans}`;

  if (customer && parseInt(customer) === Config.AUTH.SEPHORA_NA.ID) {
    apiUrl = `${ApiRoutes.getScansFromSnapshots}`;
  }

  // if (
  //   customer &&
  //   parseInt(customer) === Config.AUTH.WBA.ID &&
  //   mode &&
  //   mode === Config.DISPLAY_MODE.DAILY
  // ) {
  //   apiUrl = `${ApiRoutes.getScansFromSnapshots}`;
  // }

  if (customer) {
    apiUrl += `?customer=${customer}`;
  }

  if (source) {
    apiUrl += `&source=${source}`;
  }

  if (mode) {
    apiUrl += `&mode=${dMode}`;
  }
  if (year && month) {
    apiUrl += `&filter=year:${year}%3Bmonth:${month}`;
  }
  // if (month) {
  //   apiUrl += `&filter=month:${month};`;
  // }
  if (reportType) {
    apiUrl += `&reportType=${reportType}`;
  }
  console.log('API URL', apiUrl);
  return await api.get(apiUrl);
};

export const getDistinctSourceTypesByClient = async (clientName) => {
  if (!clientName) {
    throw Error('Please provide client name');
  }

  let apiUrl = `${ApiRoutes.getSourceTypes}${encodeURI(clientName)}`;
  const getResourceTypesResponse = await api.get(apiUrl);
  if (getResourceTypesResponse) {
    return getResourceTypesResponse.result;
  }
  return null;
};

// TODO : this is
export const getStoreAndScannerPairs = async (clientId) => {
  if (!clientId) {
    throw Error('Please provide client id');
  }
  if (!Config.AUTH.WBA.ID === clientId) {
    return null;
  }

  const getPairsResponse = await api.get(ApiRoutes.getStoreScanPairs);
  if (getPairsResponse) {
    return getPairsResponse.result;
  }
  return null;
};

export const postNewPair = async (pair) => {
  if (!pair) {
    throw Error('Please provide pair');
  }

  const postPairResponse = await api.post(ApiRoutes.postPair, pair);
  if (postPairResponse) {
    return postPairResponse.result;
  }
  return false;
};

export const getScores = async (sourceType, year, month) => {
  if (!year) {
    throw Error('Please provide month');
  }
  if (!year) {
    throw Error('Please provide month');
  }

  const wbaXPolScoresResponse = await api.get(
    `${ApiRoutes.getWbaXpolScores}?source=${sourceType}&year=${year}&month=${month}`,
  );
  if (wbaXPolScoresResponse) {
    return wbaXPolScoresResponse.result;
  }
  return [];
};

export const getDistinctAppVersions = async (customer) => {
  if (!customer) {
    throw Error('Please provide client');
  }
  const getAppVersionsResponse = await api.get(
    `${ApiRoutes.getAppVersions}?customer=${customer}`,
  );
  if (getAppVersionsResponse) {
    return getAppVersionsResponse.result;
  }
  return [];
};

export const getUploads = async () => {
  const response = await api.get(`${ApiRoutes.getUploads}`);
  if (response) {
    return response.clients;
  }
  return {};
};

export const getDistinctScanners = async (customer) => {
  if (!customer) {
    throw Error('Please provide client');
  }
  const getScannersResponse = await api.get(
    `${ApiRoutes.getScanners}?customer=${customer}`,
  );
  if (getScannersResponse) {
    return getScannersResponse.result;
  }
  return [];
};

export const getScannersUsageByAppVersion = async (customer, appVersion) => {
  return await api.get(
    `${ApiRoutes.getScannersByAppVersion}?version=${encodeURI(
      appVersion,
    )}&customer=${customer}`,
  );
};

export const getDistinctStores = async (customer) => {
  if (!customer) {
    throw Error('Please provide client');
  }
  const getStoresResponse = await api.get(
    `${ApiRoutes.getStores}?customer=${customer}`,
  );
  if (getStoresResponse) {
    return getStoresResponse.result;
  }
  return [];
};

export const getBatchTransformJobResults = async (customer) => {
  if (!customer) {
    throw Error('Please provide client');
  }
  const response = await api.get(
    `${ApiRoutes.getBatchTransformJobResults}?customer=${customer}`,
  );
  if (response) {
    return response.result;
  }
  return [];
};

export const getBatchTransformUvJobResults = async (customer) => {
  if (!customer) {
    throw Error('Please provide client');
  }
  const response = await api.get(
    `${ApiRoutes.getBatchTransformUvJobResults}?customer=${customer}`,
  );
  if (response) {
    return response.result;
  }
  return [];
};

export const getScannersUsageByStores = async (
  customer,
  year,
  month,
  store,
) => {
  if (!customer) {
    throw Error('Please provide client');
  }
  if (!year) {
    throw Error('Please provide year');
  }
  if (!month) {
    throw Error('Please provide month');
  }

  let apiUrl = `${ApiRoutes.getStoresUsage}?customer=${customer}&year=${year}&month=${month}`;

  if (store) {
    apiUrl += `&store=${store}`;
  }
  const getStoresResponse = await api.get(apiUrl);
  if (getStoresResponse) {
    return getStoresResponse.result;
  }
  return [];
};

export const validateScanners = async (scannersToCheck) => {
  if (!scannersToCheck) {
    throw Error('Please provide scanners to check');
  }
  const qatApiResponse = await axios.post(
    'https://a82sd1lmme.execute-api.ap-southeast-1.amazonaws.com/Prod/qat',
    {
      scanners: scannersToCheck,
    },
    {
      headers: {
        'content-type': 'application/json',
      },
    },
  );
  if (qatApiResponse) {
    return qatApiResponse.data;
  }
  return null;
};

export const postQatResults = async (
  scannersToCheck,
  validationResult,
  fileName,
) => {
  if (!scannersToCheck) {
    throw Error('Please provide scannersToCheck');
  }
  if (!validationResult) {
    throw Error('Please provide validationResult');
  }

  const postPairResponse = await api.post(ApiRoutes.qatResult, {
    scanners: scannersToCheck,
    response: validationResult,
    fileName,
  });
  if (postPairResponse) {
    return postPairResponse.result;
  }
  return false;
};

export const getQatValidationResults = async () => {
  let apiUrl = `${ApiRoutes.qatResults}`;

  const validationResults = await api.get(apiUrl);
  if (validationResults) {
    return validationResults.result;
  }
  return [];
};
export const getQatValidationItem = async (id) => {
  let apiUrl = `${ApiRoutes.qatResultItem}?id=${id}`;

  const validationItem = await api.get(apiUrl);
  console.log(validationItem);
  if (validationItem) {
    return validationItem.result;
  }
  return [];
};
