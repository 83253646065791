import { Config } from 'configs';

export const getNavLinks = () => {
  const { ROLES } = Config;
  const { ADMIN, CUSTOMER, UNAUTHINCATED } = ROLES;

  return [
    {
      title: 'Scan sessions',
      to: '/scan-sessions',
      roles: [ADMIN, CUSTOMER],
    },
    {
      title: 'Stores',
      to: '/stores',
      roles: [ADMIN, CUSTOMER],
    },
    // {
    //   title: 'Graph',
    //   to: '/scanners-usage-graph',
    //   roles: [CUSTOMER],
    //   customers: [Config.CUSTOMERS.WBA],
    // },
    {
      title: 'App versions',
      to: '/app-versions-usage',
      roles: [ADMIN],
    },
    {
      title: 'Uploads',
      to: '/uploads',
      roles: [ADMIN],
    },
    {
      title: 'ML',
      to: '/ml',
      roles: [ADMIN],
    },
    {
      title: 'Qat validation',
      to: '/qat-validations',
      roles: [ADMIN],
    },
    {
      title: 'Login',
      to: '/sign-in',
      roles: [UNAUTHINCATED],
      iconName: '/prisijungti_icon.png',
    },
  ];
};
