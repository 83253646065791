export const buildQueryString = (params) => {
  const esc = encodeURIComponent;
  const qs = Object.keys(params)
    .map((k) => {
      if (params[k] && k !== 'page') {
        return `${esc(k)}=${esc(params[k])}`;
      }

      return null;
    })
    .filter((x) => typeof x === 'string' && x.length > 0)
    .join('&');

  return qs;
};
