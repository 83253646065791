import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 70,
    borderTop: `1px solid ${theme.palette.primary.main}`,
  },
  link: {
    marginLeft: 10,
    marginTop: 14,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    display: 'inline-block',
  },
}));

export const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <footer>
      <div className={classes.container}>
        <Typography
          onClick={() => history.push('/terms-and-conditions')}
          className={classes.link}
        >
          {t('rules')}
        </Typography>
      </div>
    </footer>
  );
};
