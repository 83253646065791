import { Config } from 'configs';

export const getCustomers = () => {
  let customers = [
    {
      ID: -1,
      NAME: 'None',
    },
    Config.AUTH.SEPHORA_NA,
    Config.AUTH.SEPHORA_SEA,
    Config.AUTH.SEPHORA_EMEA,
    Config.AUTH.WBA,
    Config.AUTH.JPMS,
    Config.AUTH.SUPERPHARM,
    Config.AUTH.BLUSHBAR,
    Config.AUTH.ULTA,
  ];

  return customers;
};

export const getCustomersThatRunsML = () => {
  let customers = [
    {
      ID: -1,
      NAME: 'None',
    },
    Config.AUTH.SEPHORA_NA,
    Config.AUTH.WBA,
  ];

  return customers;
};
