/* eslint-disable consistent-return */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { AppBar, Toolbar } from '@material-ui/core';
// import { LocalesToolbar } from './LocalesToolbar/LocalesToolbar';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
}));

export const NavigationAppBar = ({ children, topBanner }) => {
  const classes = useStyles();

  const { alt, link, image } = topBanner || {};

  return (
    <AppBar position="static" className={classes.appBar}>
      {/* <LocalesToolbar locales={locales} /> */}
      {topBanner && (
        <a href={link}>
          <img height="171px" width="100%" alt={alt} src={image} />
        </a>
      )}
      <Toolbar disableGutters variant="regular">
        {children}
      </Toolbar>
    </AppBar>
  );
};
