import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import { Button, Grid } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { Form, SelectInput } from 'components/Controls';
import { getCustomers } from './customers';
import { Config } from 'configs';
import { getMonths, getReportMode, getYears } from './months';
import { getDistinctSourceTypesByClient } from 'api';
import Select from 'react-select';

const useStyles = makeStyles((theme) => ({
  root: {
    // border: `1px solid ${theme.palette.primary.light}`,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '10px',
  },
  input: {
    // width: '120px',
    zIndex: 100,
    marginLeft: 10,
    marginRight: 10,
  },
  selectInput: {
    // zIndex: 30000,
    marginTop: 16,
    marginBottom: 8,
    marginLeft: 10,
    marginRight: 10,
    width: '500px',
  },
  inputLabel: {
    height: '20',
  },
  clearContainer: {
    marginLeft: '20px',
  },
  labelAction: {
    marginLeft: '10px',
    color: `${theme.palette.primary.main}`,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export const Filter = ({
  customer,
  location,
  filter,
  reset,
  register,
  control,
  handleSubmit,
  defaultMonth,
  defaultYear,
  onChangeMode,
  getValues,
  reportMode,
  isLoading,
}) => {
  const classes = useStyles();
  const [searchForm, setSearchForm] = useState(null);

  const [modeIsMonthly, setModeIsMonthly] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState(customer);
  const [showSourceTypeSelection, setShowSourceTypeSelection] = useState(false);
  const [sourceTypes, setSourceTypes] = useState([]);
  const [isSourceTypesSelectionEnabled, setSourceTypesSelectionEnabled] =
    useState(false);
  const [selectedSourceType, setSelectedSourceType] = useState(null);

  const renderSourceTypes = (source) => {
    return source?.map((item) => {
      return {
        value: item.ID,
        label: item.NAME,
      };
    });
  };

  const customersList = () => {
    const customers = getCustomers().filter((customer) => customer.ID !== -1);

    return customers.map((item) => {
      return {
        value: parseInt(item.ID),
        label: item.NAME,
      };
    });
  };

  useEffect(() => {
    const form = queryString.parse(location.search);

    setSearchForm(form);
    if (form.customer) {
      setSelectedCustomer(
        customersList().find(
          (customer) => customer.value === parseInt(form.customer),
        ),
      );
    }
  }, [location.search]);

  useEffect(() => {
    const findSourceTypes = async (customerName) => {
      const sourceTypesResponse = await getDistinctSourceTypesByClient(
        customerName,
      );

      if (sourceTypesResponse) {
        sourceTypesResponse.unshift({
          ID: '-1',
          NAME: 'All',
        });
      }

      setSourceTypes(sourceTypesResponse);
    };

    const customers = getCustomers();
    const customerObject = customers.find(
      (c) => parseInt(selectedCustomer?.value || -10) === c.ID,
    );

    // all customers except JPMS has report per souce type, so we need to show source type selection
    if (customerObject && customerObject.ID !== Config.AUTH.JPMS.ID) {
      findSourceTypes(customerObject.NAME);
    }

    const showSourceTypes = selectedCustomer !== Config.AUTH.JPMS.ID;
    setShowSourceTypeSelection(showSourceTypes);

    //findSourceTypes(selectedCustomer);
  }, [selectedCustomer, customer]);

  useEffect(() => {
    setSourceTypesSelectionEnabled(sourceTypes && sourceTypes.length > 2);
    if (sourceTypes && sourceTypes.length > 0) {
      const form = queryString.parse(location.search);

      if (
        form.source &&
        form.customer &&
        parseInt(form.customer) === selectedCustomer?.value
      ) {
        setSelectedSourceType(
          renderSourceTypes(sourceTypes).find(
            (source) => source.value === form.source.toString(),
          ),
        );
      } else {
        setSelectedSourceType(renderSourceTypes(sourceTypes)[0]);
      }
    }
    // if (selectedCustomer === Config.AUTH.WBA.ID) {
    //   setSelectedSourceType('wba_instore_ios_prod');
    // }
  }, [sourceTypes]);

  const onSearch = handleSubmit((data) => {
    if (!modeIsMonthly) {
      data.mode = 'daily';
    }
    data.customer = selectedCustomer?.value || -1;
    data.source = selectedSourceType?.value || -1;

    filter(data);
  });

  const changeDisplayMode = () => {
    setModeIsMonthly(!modeIsMonthly);
    const values = getValues();
    values.month = null;

    values.source = selectedSourceType;
    values.mode = modeIsMonthly ? 'daily' : null;

    values.reportMode = Config.REPORT_TYPE.SCANNERS;

    setSearchForm(values);
    if (onChangeMode) {
      onChangeMode(
        modeIsMonthly ? Config.DISPLAY_MODE.DAILY : Config.DISPLAY_MODE.MONTHLY,
      );
    }

    filter(values);
  };

  const renderSelectItems = (items) =>
    items.map((item) => (
      <MenuItem key={item.ID} value={item.ID}>
        {item.NAME}
      </MenuItem>
    ));

  return (
    <div className={classes.root}>
      <Form>
        <Grid container direction="column" item xs={12}>
          <Grid container xs={3} item alignItems="center">
            <Select
              onChange={(selectedItem) => {
                setSelectedCustomer(selectedItem);
              }}
              control={control}
              placeholder="Select customer"
              value={selectedCustomer}
              isDisabled={isLoading}
              className={classes.selectInput}
              options={customersList()}
            />
            {/* <SelectInput
              id="customer"
              name="customer"
              label="Customer"
              control={control}
              defaultValue={searchForm?.customer || selectedCustomer}
              className={classes.selectInput}
              onChange={(selectedItem) => {
                setSelectedCustomer(parseInt(selectedItem));
              }}
            >
              {renderSelectItems(getCustomers())}
            </SelectInput> */}
          </Grid>
          {showSourceTypeSelection && (
            <Grid container xs={3} item alignItems="center">
              {/* <SelectInput
                id="source"
                name="source"
                label="Source Type"
                control={control}
                defaultValue={selectedSourceType}
                className={classes.selectInput}
                disabled={!isSourceTypesSelectionEnabled}
                onChange={(selectedItem) => {
                  setSelectedSourceType(selectedItem);
                }}
              >
                {renderSourceTypes(sourceTypes)}
              </SelectInput> */}

              <Select
                onChange={(selectedItem) => {
                  setSelectedSourceType(selectedItem);
                }}
                control={control}
                placeholder="Select source type"
                value={selectedSourceType}
                isDisabled={
                  !isSourceTypesSelectionEnabled ||
                  !selectedCustomer ||
                  isLoading
                }
                className={classes.selectInput}
                options={renderSourceTypes(sourceTypes)}
              />
            </Grid>
          )}
          <FormGroup className={classes.selectInput}>
            <FormControlLabel
              control={
                <Switch checked={modeIsMonthly} onChange={changeDisplayMode} />
              }
              label="Yearly view"
            />
          </FormGroup>
          {!modeIsMonthly && (
            <>
              {' '}
              <Grid container xs={3} item alignItems="center">
                <SelectInput
                  id="year"
                  name="year"
                  label="Year"
                  control={control}
                  defaultValue={searchForm?.year || defaultYear}
                  className={classes.selectInput}
                >
                  {renderSelectItems(getYears())}
                </SelectInput>
              </Grid>
              <Grid container xs={3} item alignItems="center">
                <SelectInput
                  id="month"
                  name="month"
                  label="Month"
                  control={control}
                  defaultValue={searchForm?.month || defaultMonth}
                  className={classes.selectInput}
                >
                  {renderSelectItems(getMonths())}
                </SelectInput>
              </Grid>
              <Grid container xs={3} item alignItems="center">
                <SelectInput
                  id="reportMode"
                  name="reportMode"
                  label="Report mode"
                  control={control}
                  defaultValue={searchForm?.reportMode || reportMode}
                  className={classes.selectInput}
                >
                  {renderSelectItems(getReportMode())}
                </SelectInput>
              </Grid>
            </>
          )}
          <Grid
            container
            item
            md={12}
            lg={12}
            xl={12}
            direction="row"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Grid
              container
              item
              xs={6}
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid item>
                <Button
                  type="submit"
                  width="170px"
                  variant="contained"
                  color="primary"
                  onClick={onSearch}
                  disabled={isLoading}
                >
                  {isLoading ? 'Searching...' : 'Search'}
                </Button>
              </Grid>
              {/* <Grid item>
                <Typography className={classes.labelAction} onClick={onClear}>
                  Clear
                </Typography>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};
