import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CircularLoader } from 'components/Controls';
import { getUploads } from 'api';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';

// import { getCustomers } from './controls/customers';
// import MyMongoChart from './mongo';

export const UploadStructureScreen = ({ location }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: '10px',
    },
    header: {
      backgroundColor: 'lightgray',
      color: theme.palette.text.primary,
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    headerRight: {
      backgroundColor: 'lightgray',
      color: theme.palette.text.primary,
      padding: theme.spacing(1),
      textAlign: 'right',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paperLeft: {
      padding: theme.spacing(1),
      textAlign: 'left',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
    },
    paperRight: {
      padding: theme.spacing(1),
      textAlign: 'right',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
    },
    pageTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 24,
      fontWeight: 'bold',
    },
    categoryTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 18,
      fontWeight: 'bold',
    },
    cell: {
      width: '100%',
    },
    thresholdCell: {
      ...theme.legendCell,
      backgroundColor: '#e2f1f8',
    },
    thresholdCellAlt: {
      ...theme.legendCell,
      backgroundColor: '#c1d5e0',
    },
    selectInput: {
      //zIndex: 100,
      marginTop: 16,
      marginBottom: 8,
      marginLeft: 10,
      marginRight: 10,
    },
    paperBold: {
      width: '110px',
      padding: theme.spacing(1),
      fontSize: 14,
      backgroundColor: '#e0ffee',
      fontWeight: 'bold',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    csvLink: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  const [uploadsStructure, setUploadsStructure] = useState([]);

  const [isLoadingTotals, setIsLoadingTotals] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchSephoraChart = useCallback(async () => {
    setIsLoading(true);

    const sdk = new ChartsEmbedSDK({
      baseUrl: 'https://charts.mongodb.com/charts-warehouse-uhqyz',
    });
    const chart = sdk.createChart({
      chartId: 'f5cbaf50-098c-43a0-8825-34385cd6bbf0', // Optional: ~REPLACE~ with the Chart ID from your Embed Chart dialog
      height: '700px',
      showAttribution: false,
      filter: {}, //chartFilter,
    });

    await chart.render(document.getElementById('chart'));

    setIsLoading(false);
  }, []);

  const fetchWbaChart = useCallback(async () => {
    setIsLoading(true);

    const sdk = new ChartsEmbedSDK({
      baseUrl: 'https://charts.mongodb.com/charts-warehouse-uhqyz',
    });
    const chart = sdk.createChart({
      chartId: 'f7acd029-65f7-4ae4-a9c2-b8077656c6a6', // Optional: ~REPLACE~ with the Chart ID from your Embed Chart dialog
      height: '700px',
      showAttribution: false,
      filter: {}, //chartFilter,
    });

    await chart.render(document.getElementById('wba_chart'));

    setIsLoading(false);
  }, []);

  const fetchUploadTotals = useCallback(async () => {
    setIsLoadingTotals(true);
    const uploads = await getUploads();
    if (uploads) {
      setUploadsStructure(uploads);
    }
    setIsLoadingTotals(false);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);

    fetchSephoraChart();
    fetchWbaChart();
    fetchUploadTotals();
    setIsLoading(false);
  }, [fetchWbaChart, fetchSephoraChart, fetchUploadTotals]);

  const renderHeader = () => {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '280px 120px 120px 120px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <div>
          <Paper className={classes.header}>
            <span>Client</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Xpol</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Uv</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Normal</span>
          </Paper>
        </div>
      </div>
    );
  };

  const renderData = (clients) => {
    let rows = [];
    if (clients && clients.length > 0) {
      clients.forEach((clientData) => {
        let outputCells = [];
        outputCells.push({
          value: clientData.client,
        });

        outputCells.push({
          value:
            clientData.sourceTotals?.find((x) => x.sourceType === 'source_xpol')
              ?.total || 'N/A',
        });
        outputCells.push({
          value:
            clientData.sourceTotals?.find((x) => x.sourceType === 'source_uv')
              ?.total || 'N/A',
        });
        outputCells.push({
          value:
            clientData.sourceTotals?.find((x) => x.sourceType === 'source')
              ?.total || 'N/A',
        });

        rows.push(outputCells);
      });
    }

    return rows.map((row, index) => (
      <div
        key={index}
        style={{
          display: 'grid',
          gridTemplateColumns: '280px 120px 120px 120px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        {row.map((cell, cellIndex) => (
          <Grid key={cellIndex} item className={classes.cell}>
            <Paper
              className={
                cellIndex >= 1 ? classes.paperRight : classes.paperRight
              }
              style={{
                backgroundColor: cell.isAnomalous ? '#ffcdd2' : undefined,
              }}
            >
              {cell.value}
            </Paper>
          </Grid>
        ))}
      </div>
    ));
  };

  const renderTotalImagesAmount = (clients) => {
    let totalXpol = 0;
    let totalUv = 0;
    let totalNormal = 0;

    if (clients && clients.length > 0) {
      clients.forEach((clientData) => {
        totalXpol +=
          clientData.sourceTotals?.find((x) => x.sourceType === 'source_xpol')
            ?.total || 0;
        totalUv +=
          clientData.sourceTotals?.find((x) => x.sourceType === 'source_uv')
            ?.total || 0;
        totalNormal +=
          clientData.sourceTotals?.find((x) => x.sourceType === 'source')
            ?.total || 0;
      });
    }

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '280px 120px 120px 120px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <div>
          <Paper className={classes.headerRight}>
            <span>
              Total ({(totalXpol + totalUv + totalNormal).toLocaleString()})
            </span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.headerRight}>
            <span>{totalXpol.toLocaleString()}</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.headerRight}>
            <span>{totalUv.toLocaleString()}</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.headerRight}>
            <span>{totalNormal.toLocaleString()}</span>
          </Paper>
        </div>
      </div>
    );
  };

  const renderGrid = () => {
    const clients = uploadsStructure;

    if (!clients) {
      return null;
    }

    return (
      <div>
        <Grid item spacing={0}>
          <div>
            <Grid item spacing={0}>
              {renderHeader()}
              {renderData(clients)}
              {renderTotalImagesAmount(clients)}
            </Grid>
          </div>
        </Grid>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div>
        <Typography className={classes.pageTitle} align="left">
          Uploads
        </Typography>
      </div>
      {!isLoadingTotals && renderGrid()}
      {isLoadingTotals && <CircularLoader />}
      <div
        id="chart"
        style={{
          width: '100%',
          height: '700px',
          display: isLoading ? 'hidden' : 'block',
        }}
      />
      <div
        id="wba_chart"
        style={{
          width: '100%',
          height: '700px',
          display: isLoading ? 'hidden' : 'block',
        }}
      />
    </div>
  );
};
