import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Context as AuthContext } from 'contexts/AuthContext';
import { HamburgerMenuNavList } from './HamburgerMenuNavList';
import { HamburgerUserMenu } from './HamburgerUserMenu';
import { Logo } from '../Logo';

export const HamburgerMenu = ({ routes }) => {
  const history = useHistory();
  const [navAnchorEl, setAnchorEl] = useState(null);
  const [userMenAnchoruEl, setUserMenuEl] = useState(null);
  const { state } = useContext(AuthContext);
  const { isSigned } = state;

  const clickHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeHandler = (to) => {
    setAnchorEl(null);
    history.push(to);
  };

  const openUserMenuHandler = () => {
    setUserMenuEl(navAnchorEl);
    setAnchorEl(null);
  };

  const closeUserMenuHandler = () => {
    setUserMenuEl(null);
  };

  return (
    <>
      <Logo />
      <Grid container justifyContent="flex-end">
        <IconButton onClick={clickHandler}>
          <MenuIcon color="secondary" fontSize="large" />
        </IconButton>
        <HamburgerMenuNavList
          routes={routes}
          anchorEl={navAnchorEl}
          onClose={closeHandler}
          onUserMenuClickHandler={openUserMenuHandler}
        />
        {isSigned && (
          <HamburgerUserMenu
            anchorEl={userMenAnchoruEl}
            onClose={closeUserMenuHandler}
          />
        )}
      </Grid>
    </>
  );
};
