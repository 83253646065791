/* eslint-disable radix */
import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useRef,
} from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { CircularLoader } from 'components/Controls';
import { getScans, getScores } from 'api';
import Paper from '@material-ui/core/Paper';
import { useForm } from 'react-hook-form';
import { CSVLink } from 'react-csv';
import { buildQueryString } from 'utils/queryStringUtils';
import queryString from 'query-string';
import { Filter } from 'views/controls/filter';
import { CustomerFilter } from 'views/controls/customerFilter';
import { Context as AuthContext } from 'contexts/AuthContext';
import { Config } from 'configs';
import { getMonths } from 'views/controls/months';

export const Scans = ({ location }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    pageTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 24,
      fontWeight: 'bold',
    },
    header: {
      backgroundColor: 'lightgray',
      color: theme.palette.text.primary,
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paperBold: {
      padding: theme.spacing(1),
      fontSize: 14,
      fontWeight: 'bold',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    row: {
      height: 40,
      width: '100%',
      textAlign: 'center',
    },
    allScansTotalCellEmpty: {
      height: 60,
      width: '100%',
      textAlign: 'center',
      backgroundColor: '#FFFFFF',
    },
    allScansTotalCell: {
      height: 60,
      width: '100%',
      textAlign: 'center',
      backgroundColor: '#A0CFD3',
    },
    totals: {
      height: 40,
      width: '100%',
      textAlign: 'center',
      backgroundColor: '#e0ffee',
    },
    totalsFirstRow: {
      height: 60,
      width: '100%',
      textAlign: 'center',
      backgroundColor: '#e0ffee',
    },
    rowWithValue: {
      backgroundColor: '#F8F8F8',
    },
    tool: {
      height: 40,
      width: '100%',
      textAlign: 'center',
      justifyContent: 'center',
    },
    storeNameHeader: {
      backgroundColor: 'lightgray',
      padding: theme.spacing(1),
    },
    storeName: {
      height: 40,
      width: '100%',
      textAlign: 'left',
      paddingLeft: 5,
      justifyContent: 'center',
    },
    storeNameLink: {
      textDecoration: 'none',
    },
    labelAction: {
      color: theme.palette.text.primary,
    },
  }));
  const classes = useStyles();

  const currentMonth = new Date().getMonth() + 1;

  const [response, setResponse] = useState(null);
  const [displayMode, setDisplayMode] = useState(Config.DISPLAY_MODE.MONTHLY);

  const [isLoading, setIsLoading] = useState(false);
  const [csvResultData, setCsvResultData] = useState(null);
  const [csvResultHeader, setCsvResultHeader] = useState(null);
  const [csvWbaScoresData, setCsvWbaScoresData] = useState([]);
  const [isFilterVisible, setIsFilterVisible] = useState(null);
  const [selectedYear, setSelectedYear] = useState(2024);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [dataDisplayMonth, setDataDisplayMonth] = useState(currentMonth);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [source, setSource] = useState(null);
  const { state: authState } = useContext(AuthContext);

  // filtering data
  const { register, handleSubmit, control, getValues } = useForm();

  // history
  const history = useHistory();

  const isCustomerWBA =
    (Config.AUTH.WBA.ID === authState.user?.id ||
      Config.AUTH.ULTA.ID === authState.user?.id) &&
    authState.user?.role === Config.ROLES.CUSTOMER;

  const isCustomerSephora =
    (Config.AUTH.SEPHORA_NA.ID === authState.user?.id ||
      Config.AUTH.SEPHORA_EMEA.ID === authState.user?.id ||
      Config.AUTH.SEPHORA_SEA.ID === authState.user?.id) &&
    authState.user?.role === Config.ROLES.CUSTOMER;

  useEffect(() => {
    const prepareCsvDataForDayView = async () => {
      const daysInMonth = getDaysInMonth(selectedYear, selectedMonth);
      const header = prepareDataToRenderMonthDaysForExport({
        days: daysInMonth,
      });

      const data = prepareDataForClientDailyView(response, true);

      setCsvResultData(data || '');
      setCsvResultHeader(header || '');
    };

    if (displayMode === Config.DISPLAY_MODE.DAILY) {
      if (response) {
        prepareCsvDataForDayView();
      } else {
        setCsvResultData(null);
      }
    }
  }, [response, dataDisplayMonth, displayMode, selectedMonth, selectedYear]);

  // Fetches scores data for WBA - This only applied for WBA
  useEffect(() => {
    (async function fetchScores() {
      if (isCustomerWBA && displayMode === Config.DISPLAY_MODE.DAILY) {
        const scores = await getScores(source, selectedYear, selectedMonth);
        setCsvWbaScoresData(scores);
      }
    })();
  }, [displayMode, selectedYear, selectedMonth, isCustomerWBA, source]);

  // Show filter. When admin - show advanced filter. When customer - show customer (custom) filter
  useEffect(() => {
    setIsFilterVisible(
      authState.user?.role === Config.ROLES.ADMIN ? true : false,
    );
  }, [authState]);

  const fetch = useCallback(async () => {
    setIsLoading(true);

    let dMode = Config.DISPLAY_MODE.MONTHLY;

    if (authState.user?.role === Config.ROLES.CUSTOMER) {
      dMode =
        isCustomerWBA || isCustomerSephora
          ? Config.DISPLAY_MODE.DAILY
          : Config.DISPLAY_MODE.MONTHLY;
      setDisplayMode(dMode);
    }

    const qs = queryString.parse(location.search);

    const { customer, month, year, mode, source, reportMode } = qs;

    console.log('query data', qs);
    setSource(source);
    // mode comes from filter
    if (mode) {
      dMode = mode;
    }
    setDisplayMode(dMode);
    if (month) {
      setSelectedMonth(month);
    }
    if (year) {
      setSelectedYear(year);
    }
    setDataDisplayMonth(month || currentMonth);

    let reportType = reportMode || Config.REPORT_TYPE.SCANNERS;

    // if (isCustomerWBA) {
    //   reportType = Config.REPORT_TYPE.SCANNERS_AND_STORES;
    // }

    if (customer) {
      setSelectedCustomer(customer);
      if (
        parseInt(customer) !== authState.user?.id &&
        authState.user?.role === Config.ROLES.CUSTOMER
      ) {
        setResponse(null);
        // setCsvResultData(null);
      } else if (customer !== '-1') {
        // if (isCustomerSephora(parseInt(customer))) {
        //   reportType = Config.REPORT_TYPE.STORES;
        // }
        console.log('fetching data for customer', {
          customer,
          dMode,
          year,
          month,
          source,
          reportType,
        });
        const scansResult = await getScans(
          customer,
          dMode,
          year,
          month,
          source,
          reportType,
        );

        if (scansResult && scansResult.result) {
          setResponse(scansResult.result);
          // prepareCsvData();
        }
      } else {
        setResponse(null);
        // setCsvResultData(null);
      }
    } else {
      // We look at supervisor portal as from admin perspective
      // for this case customer filer is not set and no call is needed
      if (authState.user?.id === -1) {
        setResponse(null);
      } else {
        const scansResult = await getScans(
          authState.user?.id,
          dMode,
          year,
          month,
          source,
          reportType,
          authState.token,
        );
        if (scansResult && scansResult.result) {
          // await fetchStoreAndScannerPairs();
          setResponse(scansResult.result);

          // prepareCsvData();
        }
      }
    }

    setIsLoading(false);
  }, [location.search, authState.user]);

  // const fetchStoreAndScannerPairs = useCallback(async () => {
  //   const pairs = await getStoreAndScannerPairs(authState.user?.id);
  //   if (pairs) {
  //     setScannerPairs(pairs);
  //   }
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    fetch();
  }, [fetch]);

  const onFilterClicked = (filterFormData) => {
    const query = buildQueryString(filterFormData);

    setDataDisplayMonth(filterFormData.month || currentMonth);

    history.push({
      pathname: '/scan-sessions',
      search: `?${query}`,
    });
  };

  const onFilterReset = () => {
    history.push({
      pathname: '/scan-sessions',
      search: `?customer=-1`,
    });
  };

  const onChangeMode = (newMode) => {
    console.log('NEW MODE', newMode);
    // setDisplayMode(newMode);
  };

  // export records by filter
  const renderHeaderForMonthView = (dates) => {
    if (!dates) {
      return null;
    }

    dates = ensureToHaveFiveMonthsDateRange(dates);

    return dates.map((date) => {
      return (
        <Grid container item sm={2}>
          <Grid item sm={12}>
            <Paper className={classes.header}>
              <strong>{date}</strong>
            </Paper>
          </Grid>
          <Grid item sm={6}>
            <Paper className={classes.header}>
              <strong>Scan sessions</strong>
            </Paper>
          </Grid>
          <Grid item sm={6}>
            <Paper className={classes.header}>
              <strong>Scanner</strong>
            </Paper>
          </Grid>
        </Grid>
      );
    });
  };

  const getMonthName = (month) => {
    const months = getMonths();
    const selectedMonth = months.find(
      (x) => x.ID.toString() === month.toString(),
    );
    if (selectedMonth) {
      return selectedMonth.NAME;
    }
    return month;
  };

  const getDaysInMonth = (yearFromFilter, monthFromFilter) => {
    const currentDate = new Date();
    let currentYear = yearFromFilter
      ? parseInt(yearFromFilter)
      : currentDate.getFullYear();
    let currentMonth = currentDate.getMonth() + 1;
    return new Date(currentYear, monthFromFilter || currentMonth, 0).getDate();
  };

  const constructTotalScansRow = (totalsObject) => {
    const total = totalsObject.scansOfTheDay.reduce(
      (partialSum, a) => partialSum + a,
      0,
    );

    let daysRendered = totalsObject.scansOfTheDay.map((scan, index) => {
      return (
        <div>
          <Paper className={classes.totalsFirstRow}>
            <span
              style={{
                height: '40px',
              }}
            >
              <div>{scan}</div>
              <div style={{ height: '10px' }}>
                <span
                  style={{
                    fontSize: '12px',
                  }}
                >
                  {totalsObject.scnnersUsedInADay[index] === 0
                    ? '-'
                    : totalsObject.scnnersUsedInADay[index]}
                </span>
              </div>
            </span>
          </Paper>
        </div>
      );
    });

    daysRendered.unshift(
      <div>
        <Paper className={classes.allScansTotalCell}>
          <span
            style={{
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            {total}
          </span>
        </Paper>
      </div>,
    );
    daysRendered.unshift(
      <div>
        <Paper className={classes.allScansTotalCellEmpty}>
          <span
            style={{
              height: '40px',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            {totalsObject.storesOrScanners} &#8681;
          </span>
        </Paper>
      </div>,
    );
    // if (isCustomerWBA) {
    //   daysRendered.unshift(
    //     <div>
    //       <Paper className={classes.allScansTotalCellEmpty}>
    //         <span>&nbsp;</span>
    //       </Paper>
    //     </div>,
    //   );
    // }
    return (
      <div
        style={{
          display: 'grid',
          // gridTemplateColumns: isCustomerWBA
          //   ? '140px 100px repeat(35, 50px)'
          //   : '100px repeat(34, 60px)',
          gridTemplateColumns: '100px repeat(34, 60px)',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '30px',
          verticalAlign: 'middle',
        }}
      >
        {daysRendered}
      </div>
    );
  };

  const prepareDataToRenderMonthDays = ({
    days,
    fn,
    monthDaysSet,
    totalScansOfScanner,
  }) => {
    let daysRendered = Array.from(Array(days).keys()).map((day) => {
      const dayNumber = day + 1;
      let cellClass = fn ? classes.row : classes.header;

      const val = fn && fn(dayNumber, monthDaysSet);

      if (val && val > 0) {
        cellClass = classes.rowWithValue;
      }

      return (
        <div>
          <Paper className={cellClass}>
            {(fn && (
              <span
                style={{
                  height: '40px',
                }}
              >
                {fn(dayNumber, monthDaysSet)}
              </span>
            )) || <span>{dayNumber}</span>}
          </Paper>
        </div>
      );
    });

    daysRendered.unshift(
      <div>
        <Paper className={fn ? classes.totals : classes.header}>
          {(fn && (
            <span
              style={{
                height: '40px',
              }}
            >
              {totalScansOfScanner}
            </span>
          )) || <span>&nbsp;</span>}
        </Paper>
      </div>,
    );

    if (monthDaysSet && monthDaysSet.tool_id) {
      daysRendered.unshift(
        <div>
          <Paper className={classes.tool}>{monthDaysSet.tool_id}</Paper>
        </div>,
      );

      // if (isCustomerWBA) {
      //   daysRendered.unshift(
      //     <div>
      //       <Paper className={classes.tool}>{monthDaysSet.store_id}</Paper>
      //     </div>,
      //   );
      // }
    } else {
      daysRendered.unshift(<div className={classes.header}>&nbsp;</div>);

      // if (isCustomerWBA) {
      //   daysRendered.unshift(
      //     <div className={classes.storeNameHeader}>&nbsp;</div>,
      //   );
      // }
    }

    return (
      <div
        style={{
          display: 'grid',
          // gridTemplateColumns: isCustomerWBA
          //   ? '140px 100px repeat(35, 50px)'
          //   : '100px repeat(34, 60px)',
          gridTemplateColumns: '100px repeat(34, 60px)',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '40px',
          verticalAlign: 'middle',
        }}
      >
        {daysRendered}
      </div>
    );
  };

  const prepareDataToRenderMonthDaysForExport = ({
    days,
    fn,
    monthDaysSet,
  }) => {
    let daysRendered = Array.from(Array(days).keys()).map((day) => {
      const dayNumber = day + 1;
      return (fn && fn(dayNumber, monthDaysSet)) || dayNumber.toString();
    });
    if (monthDaysSet && monthDaysSet.tool_id) {
      daysRendered.unshift(monthDaysSet.tool_id.toString());

      // if (isCustomerWBA) {
      //   // daysRendered.unshift(getStoreNameByScannerId(monthDaysSet.tool_id));
      //   daysRendered.unshift(monthDaysSet.store_id);
      // }
    } else {
      daysRendered.unshift(new Date().getFullYear() + '-' + dataDisplayMonth);
      // if (isCustomerWBA) {
      //   daysRendered.unshift('');
      // }
    }

    return daysRendered;
  };

  const renderHeaderForDailyView = () => {
    const daysInMonth = getDaysInMonth(selectedYear, selectedMonth);
    return prepareDataToRenderMonthDays({ days: daysInMonth });
  };

  const renderHeaderSideBar = () => {
    if (displayMode === Config.DISPLAY_MODE.MONTHLY) {
      return (
        <Grid container item sm={2}>
          <Grid item sm={12}>
            <Paper className={classes.header}>&nbsp;</Paper>
          </Grid>
          <Grid item sm={12}>
            <Paper className={classes.header}>&nbsp;</Paper>
          </Grid>
        </Grid>
      );
    }
    return null;
  };
  const renderHeader = (dates) => {
    if (displayMode === Config.DISPLAY_MODE.MONTHLY) {
      return renderHeaderForMonthView(dates);
    }
    if (displayMode === Config.DISPLAY_MODE.DAILY) {
      return renderHeaderForDailyView();
    }
    return null;
  };

  const ensureToHaveFiveMonthsDateRange = (dates) => {
    const datesRangeLength = dates.length;

    if (datesRangeLength < 5) {
      let lastDate = new Date(dates[datesRangeLength - 1]);

      for (let i = datesRangeLength; i < 5; i++) {
        dates.push(lastDate.getYear() + 1900 + '-' + lastDate.getMonth());
        lastDate.setMonth(lastDate.getMonth() - 1);
      }
    }

    return dates;
  };

  const renderClientForMontlyView = (result) => {
    if (!result) {
      return null;
    }

    let { dates, clients, values } = result;
    dates = ensureToHaveFiveMonthsDateRange(dates);

    let outputCells = [];

    clients.forEach((client) => {
      let totals = [];
      let datesArray = [];

      dates.forEach((date) => {
        datesArray[`${date}`] = [];
      });

      values.forEach((value) => {
        const valueParts = value.key.split('___');
        const vDate = valueParts[0];
        const vClient = valueParts[1];
        const vScanner = valueParts[2];

        if (client === vClient) {
          datesArray[`${vDate}`].push({
            scans: value.value,
            scanner: vScanner,
          });
        }
      });

      let longestMonthArray = 0;
      dates.forEach((date) => {
        if (datesArray[`${date}`].length > longestMonthArray) {
          longestMonthArray = datesArray[`${date}`].length;
        }
        totals[date] = { scans: 0, scanners: 0 };
      });

      for (let i = 0; i < longestMonthArray; i++) {
        if (i === 0) {
          // outputCells.push(client);
          outputCells.push('Total');
        } else {
          outputCells.push('');
          outputCells.push('');
        }

        dates.forEach((date) => {
          const dateArrayLegth = datesArray[`${date}`].length;
          if (dateArrayLegth > i) {
            const cellValue = datesArray[`${date}`][i];
            totals[date].scans += cellValue.scans;
            totals[date].scanners++;
            outputCells.push(cellValue.scans);
            outputCells.push(cellValue.scanner);
          } else {
            outputCells.push('');
            outputCells.push('');
          }
        });
      }

      let totalsInsertCellIndex = 0;
      dates.forEach((date) => {
        const t = totals[date];
        outputCells.splice(totalsInsertCellIndex + 1, 0, t.scans);
        outputCells.splice(totalsInsertCellIndex + 2, 0, t.scanners);
        totalsInsertCellIndex += 2;
      });
      outputCells.splice(totalsInsertCellIndex + 1, 0, '');
      outputCells.splice(totalsInsertCellIndex + 2, 0, '');
    });

    if (!outputCells) {
      return null;
    }

    return outputCells.map((cell, index) => {
      return (
        <Grid key={index} item sm={index === 0 ? 2 : 1}>
          {cell === '' && <Paper className={classes.paper}>&nbsp;</Paper>}
          {cell !== '' && (
            <Paper className={index <= 10 ? classes.paperBold : classes.paper}>
              {cell}
            </Paper>
          )}
        </Grid>
      );
    });
  };

  const prepareDataForClientDailyView = (result, forExport) => {
    const daysInMonth = getDaysInMonth(selectedYear, selectedMonth);
    const renderFunction = (day, resultSet) => {
      const scansInThatDay = resultSet.days.find((r) => r.day === day);

      if (scansInThatDay) {
        return scansInThatDay.total.toString();
      }
      return '0';
    };

    let rows = [];
    let totals = {
      storesOrScanners: 0,
      scansOfTheDay: [],
      scnnersUsedInADay: [],
    };

    if (result && result.length > 0) {
      totals.storesOrScanners = result.length;

      result?.forEach((resultItem) => {
        let scannerTotals = 0;

        if (resultItem && resultItem.days && resultItem.days.length > 0) {
          resultItem.days.forEach((totalsThatDay) => {
            scannerTotals += totalsThatDay.total;
            if (!totals.scansOfTheDay[totalsThatDay.day - 1]) {
              totals.scansOfTheDay[totalsThatDay.day - 1] = 0;
            }
            totals.scansOfTheDay[totalsThatDay.day - 1] += totalsThatDay.total;
            if (!totals.scnnersUsedInADay[totalsThatDay.day - 1]) {
              totals.scnnersUsedInADay[totalsThatDay.day - 1] = 0;
            }
            totals.scnnersUsedInADay[totalsThatDay.day - 1] += 1;
          });
        }

        const renderRequest = {
          days: daysInMonth,
          fn: renderFunction,
          monthDaysSet: resultItem,
          totalScansOfScanner: scannerTotals,
        };

        const row = forExport
          ? prepareDataToRenderMonthDaysForExport(renderRequest)
          : prepareDataToRenderMonthDays(renderRequest);

        rows.push(row);
      });
    }

    for (let i = 0; i < daysInMonth; i++) {
      if (!totals.scansOfTheDay[i]) {
        totals.scansOfTheDay[i] = 0;
      }
      if (!totals.scnnersUsedInADay[i]) {
        totals.scnnersUsedInADay[i] = 0;
      }
    }

    if (!forExport) {
      const totalsRow = constructTotalScansRow(totals);
      rows.unshift(totalsRow);
    }

    return rows.map((r) => {
      return r;
    });
  };

  const renderClients = (result) => {
    if (displayMode === Config.DISPLAY_MODE.MONTHLY) {
      return renderClientForMontlyView(result);
    }
    if (displayMode === Config.DISPLAY_MODE.DAILY) {
      return prepareDataForClientDailyView(result);
    }
    return null;
  };

  const renderGrid = (dates, response) => {
    if (displayMode === Config.DISPLAY_MODE.MONTHLY) {
      return (
        <Grid container spacing={0}>
          {renderHeaderSideBar()}
          {renderHeader(dates)}
          {renderClients(response)}
        </Grid>
      );
    }
    if (displayMode === Config.DISPLAY_MODE.DAILY) {
      return (
        <div>
          {renderHeaderSideBar()}
          {renderHeader(dates)}
          {renderClients(response)}
        </div>
      );
    }
    return null;
  };

  const fullWidthGrid = () => {
    if (!response) {
      return <div>No data</div>;
    }

    if (displayMode === Config.DISPLAY_MODE.DAILY && response.length === 0) {
      return <div>No data</div>;
    }

    const { dates } = response;
    if (displayMode === Config.DISPLAY_MODE.MONTHLY) {
      if (response?.values?.length === 0) {
        return null;
      }
    }

    return (
      <div>
        <Grid className={classes.filterItem} item>
          {csvResultData &&
            csvResultHeader &&
            displayMode === Config.DISPLAY_MODE.DAILY && (
              <CSVLink
                filename={`${getMonthName(dataDisplayMonth)}.csv`}
                data={csvResultData}
                headers={csvResultHeader}
              >
                <Typography className={classes.labelAction} align="left">
                  Export {getMonthName(dataDisplayMonth)} data to CSV
                </Typography>
              </CSVLink>
            )}
        </Grid>
        <Grid className={classes.filterItem} item>
          {isCustomerWBA &&
            csvWbaScoresData &&
            displayMode === Config.DISPLAY_MODE.DAILY &&
            source !== undefined && (
              <CSVLink
                filename={`${getMonthName(dataDisplayMonth)}-scores.csv`}
                data={csvWbaScoresData}
              >
                <Typography className={classes.labelAction} align="left">
                  Export {getMonthName(dataDisplayMonth)} L.b.a scores to CSV
                </Typography>
              </CSVLink>
            )}
        </Grid>
        <Grid container spacing={0}>
          {renderGrid(dates, response)}
        </Grid>
      </div>
    );
  };

  // rendering
  return (
    <div className={classes.root}>
      <div>
        <Typography className={classes.pageTitle} align="left">
          Scan Sessions
        </Typography>
      </div>
      {authState.user?.role === Config.ROLES.ADMIN && (
        <Grid container direction="column">
          <Grid className={classes.filterItem} item>
            <Filter
              customer={selectedCustomer}
              customerName={authState.user?.customer}
              filter={onFilterClicked}
              reset={onFilterReset}
              register={register}
              handleSubmit={handleSubmit}
              location={location}
              control={control}
              onChangeMode={onChangeMode}
              defaultMonth={selectedMonth}
              defaultYear={selectedYear}
              getValues={getValues}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      )}
      {authState.user?.role === Config.ROLES.CUSTOMER && (
        <Grid container direction="column">
          <Grid className={classes.filterItem} item>
            <CustomerFilter
              customer={authState.user?.id}
              customerName={authState.user?.customer}
              filter={onFilterClicked}
              reset={onFilterReset}
              register={register}
              handleSubmit={handleSubmit}
              location={location}
              control={control}
              defaultMonth={selectedMonth}
              defaultYear={selectedYear}
              getValues={getValues}
            />
          </Grid>
        </Grid>
      )}
      <div style={{ marginTop: 30 }}></div>
      {!isLoading && fullWidthGrid()}
      {isLoading && <CircularLoader />}
    </div>
  );
};
