import React, { useContext } from 'react';
import { SnackbarProvider } from 'notistack';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Container, useMediaQuery } from '@material-ui/core';
import { AppHeader } from 'components/Navigation/AppHeader';
import { Footer } from 'components/Navigation/Footer';
import { getNavLinks } from 'components/Navigation/links';
import { SnackbarUtilsConfigurator } from 'utils/snackbarUtils';
import { Context as AuthContext } from 'contexts/AuthContext';
import { Config } from 'configs';

const useStyles = makeStyles((theme) => ({
  appContainer: {
    backgroundColor: 'white',
  },
  main: {
    width: '100%',
    backgroundColor: 'white',
  },
  contentContainer: {
    flexGrow: 1,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    backgroundColor: 'white',
  },
}));

export const BaseLayout = ({ children, topBanner }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const navLinks = getNavLinks();
  const { state } = useContext(AuthContext);
  const { user } = state;
  const userRole = user?.role || Config.ROLES.UNAUTHINCATED;

  const filteredNavLinks = navLinks.filter((navItem) => {
    let returnNavItem = false;

    if (navItem.roles.includes(userRole)) {
      returnNavItem = true;
    }

    if (navItem.customers) {
      return navItem.customers.includes(user.customer);
    }
    return returnNavItem;
  });

  const Header = () => (
    <AppHeader
      matches={matches}
      navLinks={filteredNavLinks}
      topBanner={topBanner}
    />
  );

  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={4000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <SnackbarUtilsConfigurator />
      <Container className={classes.appContainer} maxWidth="xl">
        <Header />
        <main className={classes.main}>
          <div className={classes.contentContainer}>{children}</div>
        </main>
        {/* <Footer /> */}
      </Container>
    </SnackbarProvider>
  );
};
