import { SnackbarUtils } from 'utils/snackbarUtils';
import { history } from 'utils/history';
import { createDataContext } from './createDataContext';
import { Config } from 'configs';
import { uuid } from 'uuidv4';
import { Auth } from 'aws-amplify';

const reducer = (state, action) => {
  switch (action.type) {
    case 'set_loading':
      return { ...state, isLoading: action.payload };
    case 'set_initialized':
      return { ...state, initialized: true };
    case 'signin':
      return {
        isLoading: state.isLoading,
        errorMessage: null,
        token: action.payload.token,
        user: action.payload.user,
        isSigned: true,
        initialized: true,
      };
    case 'logout':
      return {
        isSigned: false,
        errorMessage: null,
        token: null,
        refreshToken: null,
        user: null,
        initialized: true,
      };
    case 'set_error':
      return { ...state, errorMessage: action.payload };
    case 'clear_error':
      return { ...state, errorMessage: null };
    default:
      return state;
  }
};

const initService = (dispatch) => async () => {
  const token = localStorage.getItem('token');

  if (!token) {
    dispatch({ type: 'set_initialized' });
    return;
  }

  const userAsString = localStorage.getItem('user');

  const user = JSON.parse(userAsString);

  dispatch({ type: 'signin', payload: { token, user } });
  dispatch({ type: 'set_initialized' });
};

const signin =
  (dispatch) =>
  async ({ email, password }, to) => {
    dispatch({ type: 'set_loading', payload: true });

    const token = uuid();
    // TODO: implement AUTH. AWS cognito ?

    try {
      const user = await Auth.signIn(email, password);
      const currentUserInfo = await Auth.currentUserInfo();

      const idToken = user.signInUserSession.idToken;
      const refreshToken = user.signInUserSession.refreshToken;

      const company = currentUserInfo.attributes['custom:company'];
      let userId = parseInt(currentUserInfo.attributes['custom:ID']);
      const username = currentUserInfo.username;

      if (user) {
        const userRole =
          userId === Config.AUTH.ADMIN.ID
            ? Config.ROLES.ADMIN
            : userId === Config.AUTH.QAT.ID
            ? Config.ROLES.QAT
            : Config.ROLES.CUSTOMER;

        let redirectLink = to;

        if (userRole === Config.ROLES.CUSTOMER) {
          redirectLink = to + '?customer=' + userId;
        }

        if (userRole === Config.ROLES.QAT) {
          redirectLink = 'qat-validations';
        }

        const authenticatedUser = {
          id: userId,
          role: userRole,
          customer: company,
          cognitoUserName: username,
          // division: user.DIVISION,
        };

        console.log('redirectLink', redirectLink, authenticatedUser);

        dispatch({
          type: 'signin',
          payload: { token: token, user: authenticatedUser },
        });

        localStorage.setItem('user', JSON.stringify(authenticatedUser));
        localStorage.setItem('token', idToken);
        localStorage.setItem('refreshToken', refreshToken);

        history.replace(redirectLink);
      } else {
        throw new Error('Unauthorized');
      }
    } catch (e) {
      console.log('e', e);
      dispatch({ type: 'set_error', payload: 'Unauthorized' });
    }
  };

const logout = (dispatch) => async (to) => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('user');
  await Auth.signOut();
  dispatch({ type: 'logout' });

  try {
    SnackbarUtils.success('Logout success');
    history.replace(to);
  } catch (e) {
    SnackbarUtils.error('Unknown error');
  }
};

const clearErrorMessage = (dispatch) => () => {
  dispatch({ type: 'set_error', payload: null });
};

export const { Context, Provider } = createDataContext(
  reducer,
  {
    initService,
    signin,
    logout,
    clearErrorMessage,
  },
  {
    isSigned: false,
    error: null,
    errorMessage: null,
    token: null,
    refreshToken: null,
    user: null,
    isLoading: false,
    initialized: false,
  },
);
