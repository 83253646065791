import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CircularLoader } from 'components/Controls';
import {
  getDistinctAppVersions,
  getDistinctScanners,
  getScannersUsageByAppVersion,
} from 'api';
import { Form } from 'components/Controls';
import Select from 'react-select';
import { CSVLink } from 'react-csv';

import { getCustomers } from './controls/customers';

export const AppVersionsScreen = ({ location }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      //marginLeft: theme.spacing(2),
      border: '1px solid rgba(0, 0, 0, 0.12)',
      padding: '10px',
    },
    pageTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 24,
      fontWeight: 'bold',
    },
    selectInput: {
      //zIndex: 100,
      marginTop: 16,
      marginBottom: 8,
      marginLeft: 10,
      marginRight: 10,
    },
    paper: {
      width: '110px',
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paperBold: {
      width: '110px',
      padding: theme.spacing(1),
      fontSize: 14,
      backgroundColor: '#e0ffee',
      fontWeight: 'bold',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    csvLink: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  const customersTransformed = () => {
    const customers = getCustomers().filter((customer) => customer.ID !== -1);

    return customers.map((item) => {
      return {
        value: item.ID,
        label: item.NAME,
      };
    });
  };

  const [customers] = useState(customersTransformed() || []);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedAppVersion, setSelectedAppVersion] = useState(null);
  const [scanners, setScanners] = useState([]);
  const [appVersions, setAppVersions] = useState([]);
  const [csvResultData, setCsvResultData] = useState(null);

  const [appVersionUsageResponse, setAppVersionUsageResponse] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      if (selectedCustomer) {
        setCsvResultData(null);
        const appVersionsResult = await getDistinctAppVersions(
          selectedCustomer.value,
        );

        const convertedAppVersions = appVersionsResult.map((item) => {
          return {
            value: item.name,
            label: item.name,
          };
        });

        setAppVersions(convertedAppVersions);

        const scannersResult = await getDistinctScanners(
          selectedCustomer.value,
        );

        setScanners(scannersResult);

        if (convertedAppVersions && convertedAppVersions.length > 0) {
          setSelectedAppVersion(convertedAppVersions[0]);
        }
      }

      setIsLoading(false);
    };
    fetchData();
  }, [selectedCustomer]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      if (selectedCustomer && selectedAppVersion) {
        const usageResult = await getScannersUsageByAppVersion(
          selectedCustomer.value,
          selectedAppVersion.value,
        );

        if (
          usageResult &&
          usageResult.result &&
          usageResult.result.length === 1
        ) {
          const usedScanners = usageResult.result[0].scanners;
          setAppVersionUsageResponse(usedScanners);

          const unusedScanners = scanners?.filter((scanner) => {
            return !usedScanners.includes(scanner.name);
          });

          setCsvResultData(unusedScanners?.map((item) => [item.name]));
        }
      }

      setIsLoading(false);
    };
    fetchData();
  }, [selectedAppVersion]);

  const renderAppVersionsGrid = (allScanners, usedScanners) => {
    const scannerIsUsed = (scanner) => {
      return usedScanners?.find((usedScanner) => {
        return usedScanner === scanner.name;
      });
    };

    if (allScanners && allScanners.length > 0) {
      return allScanners.map((cell, index) => {
        return (
          <Grid item>
            <Paper
              className={
                scannerIsUsed(cell) ? classes.paperBold : classes.paper
              }
            >
              {cell.name}
            </Paper>
          </Grid>
        );
      });
    }
    return null;
  };

  return (
    <div>
      <div>
        <Typography className={classes.pageTitle} align="left">
          App Versions Usage
        </Typography>
      </div>
      <div className={classes.root}>
        <Form>
          <Grid container direction="column" item xs={12}>
            <Grid item alignItems="center" xs={3}>
              <Select
                onChange={(selectedItem) => {
                  setSelectedCustomer(selectedItem);
                }}
                placeholder="Select client"
                className={classes.selectInput}
                options={customers}
              />
            </Grid>
            <Grid item alignItems="center" xs={3}>
              <Select
                onChange={(selectedItem) => {
                  setSelectedAppVersion(selectedItem);
                }}
                placeholder="Choose app version"
                isDisabled={isLoading || !selectedCustomer}
                value={selectedAppVersion}
                options={appVersions}
                className={classes.selectInput}
              />
            </Grid>
          </Grid>
          <Grid className={classes.csvLink} item>
            {csvResultData && !isLoading && (
              <CSVLink
                filename={`${selectedAppVersion?.label}.csv`}
                data={csvResultData}
              >
                <Typography className={classes.labelAction} align="left">
                  Export unused scanners for {selectedAppVersion?.label} version
                  to CSV
                </Typography>
              </CSVLink>
            )}
          </Grid>
        </Form>
      </div>
      <div style={{ marginTop: 30 }}>
        {!isLoading && (
          <Grid container spacing={0}>
            <Grid container spacing={0}>
              {renderAppVersionsGrid(scanners, appVersionUsageResponse)}
            </Grid>
          </Grid>
        )}
      </div>
      {isLoading && <CircularLoader />}
    </div>
  );
};
