import { React } from 'react';
import { MainContainer } from 'components/Controls';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from 'utils/snackbarUtils';

export const BlankLayout = ({ children, props }) => (
  <SnackbarProvider
    maxSnack={1}
    autoHideDuration={4000}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
  >
    <SnackbarUtilsConfigurator />
    <MainContainer {...props}>{children}</MainContainer>
  </SnackbarProvider>
);
