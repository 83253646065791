import React from 'react';

const logoStyles = {
  image: {
    float: 'left',
    width: '151px',
    maxWidth: '100%',
    height: '70px',
    margin: '10px',
    clear: 'none',
    display: 'block',
  },
};

export const Logo = () => (
  <a href="/scan-sessions">
    <img
      style={logoStyles.image}
      src={`${process.env.PUBLIC_URL}/logo512.png`}
      alt="logo"
    />
  </a>
);
