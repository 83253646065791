/* eslint-disable react-hooks/rules-of-hooks */
import { useContext } from 'react';
import { Context as AuthContext } from 'contexts/AuthContext';

export const getUserMenuLinks = () => {
  const { logout } = useContext(AuthContext);

  const logoutHandler = () => {
    logout('scan-sessions');
  };

  return [
    {
      title: 'Logout',
      onClick: logoutHandler,
      includeDivider: true,
    },
  ];
};
