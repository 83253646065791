import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ListItem, ListItemText, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: '25px',
    height: '50%',
    backgroundColor: 'none',
    color: theme.palette.primary.main,
    width: 'auto',
  },
  simpleButton: {
    '&:hover': {
      background: 'none',
    },
  },
  importantButton: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '11px',
    [theme.breakpoints.down('lg')]: {
      marginRight: 10,
    },
    '&:hover': {
      background: theme.palette.secondary.main,
      color: '#00ced1',
    },
  },
  simpleLinkText: {
    fontSize: 16,
    [theme.breakpoints.down('md')]: {
      paddingRight: 10,
      fontSize: 12,
    },
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  importantLinkText: {
    fontSize: 16,
    [theme.breakpoints.down('md')]: {
      paddingRight: 10,
      fontSize: 12,
    },
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    fontWeight: '800',
  },
}));

export const NavigationItem = ({
  title, //
  to,
  important,
  iconName,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const matchesSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ListItem
      className={`${classes.button} ${
        important ? classes.importantButton : classes.simpleButton
      }`}
      button
      component={NavLink}
      to={to}
      activeStyle={important ? {} : classes.linkActive}
      disableGutters={!!matchesSmall}
      exact={to === '/'}
    >
      {iconName && (
        <div style={{ marginRight: 5 }}>
          <img src={iconName} alt="" />
        </div>
      )}
      {/* <ListItemText
        classes={{
          primary: important
            ? classes.importantLinkText
            : classes.simpleLinkText,
        }}
        primary={title}
      /> */}
      <ListItemText
        classes={{
          primary:
            location.pathname === to
              ? classes.importantLinkText
              : classes.simpleLinkText,
        }}
        primary={title}
      />
    </ListItem>
  );
};
