import React, { useState, useRef } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { Controller } from 'react-hook-form';

export const SelectInput = React.forwardRef(
  (
    {
      name,
      label,
      control,
      defaultValue,
      children,
      onChange,
      disabled,
      ...props
    },
    ref,
  ) => {
    const labelId = `${name}-label`;
    const [val, setVal] = useState(defaultValue);

    const onSelectionChange = (selectedItem) => {
      setVal(selectedItem);
      if (onChange) {
        onChange(selectedItem);
      }
    };

    const isDisabled = disabled ? disabled : false;

    return (
      <FormControl variant="outlined" size="small" fullWidth {...props}>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Controller
          render={({ field: { onChange } }) => (
            <Select
              labelId={labelId}
              ref={ref}
              label={label}
              onChange={(e) => {
                onSelectionChange(e.target.value);
                onChange(e);
              }}
              value={val || ''}
              disabled={isDisabled}
              style={{ backgroundColor: isDisabled ? '#f5f5f5' : undefined }}
            >
              {children}
            </Select>
          )}
          name={name}
          control={control}
          defaultValue={defaultValue}
        />
      </FormControl>
    );
  },
);
