import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Context as AuthContext } from 'contexts/AuthContext';
import { BlankLayout } from 'components/Layouts/BlankLayout';
import { Config } from 'configs';

const defaultProps = {
  layout: BlankLayout,
  redirectTo: '/sign-in',
  MultipleRedirects: null,
};

const getRedirectPage = (redirectTo, MultipleRedirects, userRole) => {
  let redirectPage = redirectTo;

  if (MultipleRedirects) {
    const { redirectTo: redirectToResult } = MultipleRedirects.find(
      (oneRedirect) => !oneRedirect.Roles.includes(userRole),
    );

    if (redirectToResult) {
      redirectPage = redirectToResult;
    }
  }

  return redirectPage;
};

export const LayoutRoute = ({
  component,
  layout,
  Roles,
  redirectTo,
  MultipleRedirects,
  ...routeProps
}) => {
  const Layout = layout;
  const Component = component;
  const { state } = useContext(AuthContext);
  const { user } = state;
  let { role: userRole } = user || {};

  if (!userRole) userRole = Config.ROLES.UNAUTHINCATED;

  const needToredirect =
    MultipleRedirects || (Roles && !Roles.includes(userRole));

  if (needToredirect) {
    return (
      <Redirect to={getRedirectPage(redirectTo, MultipleRedirects, userRole)} />
    );
  }

  return (
    <Route
      {...routeProps}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

LayoutRoute.defaultProps = defaultProps;
