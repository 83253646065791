import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Form, SelectInput } from 'components/Controls';
import { getMonths, getReportMode, getYears } from './months';
import { Config } from 'configs';
import { getDistinctSourceTypesByClient } from 'api';

const useStyles = makeStyles((theme) => ({
  root: {
    // border: `1px solid ${theme.palette.primary.light}`,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '10px',
  },
  input: {
    // width: '120px',
    zIndex: 100,
    marginLeft: 10,
    marginRight: 10,
  },
  selectInput: {
    zIndex: 100,
    marginTop: 16,
    marginBottom: 8,
    marginLeft: 10,
    marginRight: 10,
  },
  inputLabel: {
    height: '20',
  },
  clearContainer: {
    marginLeft: '20px',
  },
  labelAction: {
    marginLeft: '10px',
    color: `${theme.palette.primary.main}`,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export const CustomerFilter = ({
  customer,
  customerName,
  location,
  filter,
  reset,
  register,
  control,
  handleSubmit,
  getValues,
  defaultMonth,
  defaultYear,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [searchForm, setSearchForm] = useState(
    queryString.parse(location.search),
  );
  const [showSourceTypeSelection, setShowSourceTypeSelection] = useState(false);
  const [sourceTypes, setSourceTypes] = useState(false);
  const [selectedSourceType, setSelectedSourceType] = useState(null);
  const [displayModeAsChart, setDisplayModeAsChart] = useState(false);

  useEffect(() => {
    const form = queryString.parse(location.search);
    setSearchForm(form);
  }, [location.search]);

  useEffect(() => {
    const findSourceTypes = async (name) => {
      const sourceTypesResponse = await getDistinctSourceTypesByClient(name);
      setSourceTypes(sourceTypesResponse);
      setShowSourceTypeSelection(true);
    };

    // all customers except JPMS has report per souce type, so we need to show source type selection
    if (customer !== Config.AUTH.JPMS.ID) {
      findSourceTypes(customerName);
    }
  }, [customer, customerName]);

  // useEffect(() => {
  //   if (customer === Config.AUTH.WBA.ID) {
  //     setSelectedSourceType('wba_instore_ios_prod');
  //   }
  // }, [sourceTypes, customer]);

  const renderSelectItems = (items) =>
    items?.map((item) => (
      <MenuItem key={item.ID} value={item.ID}>
        {item.NAME}
      </MenuItem>
    ));

  const renderSourceTypes = (items) => {
    if (!items) {
      return null;
    }
    return items?.map((item) => (
      <MenuItem key={item.ID} value={item.ID}>
        {item.NAME}
      </MenuItem>
    ));
  };

  return (
    <div className={classes.root}>
      <Form>
        <Grid container direction="column" item xs={12}>
          <Grid container item alignItems="center">
            <SelectInput
              id="year"
              name="year"
              label="Year"
              control={control}
              onChange={(selectedItem) => {
                let values = getValues();
                values.year = selectedItem;
                filter(values);
              }}
              defaultValue={searchForm?.year || defaultYear}
              className={classes.selectInput}
            >
              {renderSelectItems(getYears())}
            </SelectInput>
          </Grid>
          <Grid container item alignItems="center">
            <SelectInput
              id="month"
              name="month"
              label="Month"
              control={control}
              onChange={(selectedItem) => {
                let values = getValues();
                values.month = selectedItem;
                filter(values);
              }}
              defaultValue={searchForm?.month || defaultMonth}
              className={classes.selectInput}
            >
              {renderSelectItems(getMonths())}
            </SelectInput>
          </Grid>
          {showSourceTypeSelection && (
            <Grid container item alignItems="center">
              <SelectInput
                id="source"
                name="source"
                label="Source Type"
                control={control}
                onChange={(selectedItem) => {
                  let values = getValues();
                  values.source = selectedItem;
                  filter(values);
                }}
                defaultValue={searchForm?.source || selectedSourceType}
                className={classes.selectInput}
              >
                {renderSourceTypes(sourceTypes)}
              </SelectInput>
            </Grid>
          )}
          <Grid container item alignItems="center">
            <SelectInput
              id="reportMode"
              name="reportMode"
              label="Report mode"
              control={control}
              defaultValue={
                searchForm?.reportMode || Config.REPORT_TYPE.SCANNERS
              }
              className={classes.selectInput}
              onChange={(selectedItem) => {
                let values = getValues();
                values.reportMode = selectedItem;
                filter(values);
              }}
            >
              {renderSelectItems(getReportMode())}
            </SelectInput>
          </Grid>
          {/* <Grid
            container
            item
            md={12}
            lg={12}
            xl={12}
            direction="row"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Grid
              container
              item
              xs={6}
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid item>
                <Button
                  type="submit"
                  width="170px"
                  variant="contained"
                  color="primary"
                  onClick={onSearch}
                >
                  Search
                </Button>
              </Grid>
              <Grid item>
                <Typography className={classes.labelAction} onClick={onClear}>
                  Clear
                </Typography>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </Form>
    </div>
  );
};
